import "./App.css";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { HashRouter as Router, Route } from "react-router-dom";
import Home from "./containers/home";
import PrivacyPolicy from "./containers/privacy-policy";

const theme = {
  main: "#303952",
  secondary: "#596275",
  mobileBreakpoint: "800px",
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <GlobalStyle />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
  }

  body{
    padding: 20px;
  }

  html{
    background: #182848;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-height: 100%;
  }

  body {
    font-family: 'Roboto', sans-serif;
    color: #fff;
  }

  h1, h2, button, a{
    font-family: 'Montserrat', sans-serif;
    text-shadow: 2px 2px 3px rgba(0,0,0,.6);
  }
`;
