import React, { Component } from "react";
import styled, { createGlobalStyle } from "styled-components";
import ScrollableBox from "../components/scrollablebox";
import { Link } from "react-router-dom";
import { StyledLink } from "./home";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <Wrapper>
        <StyledLink to="/">Home</StyledLink>
        <ScrollableBox>
          <div>
            <h2>Privacy Policy</h2>
            <p>
              Last updated: <strong>October 21st, 2021</strong>.
            </p>
            <h3>1.0 Information We Collect</h3>
            <p>
              The IPTV Time App is committed to protecting the privacy of its
              users. We do not collect any personal data from our users, nor do
              we track any user data. We do not share or sell any user
              information to third parties.
            </p>
            <h3>1.1 Changes to This Privacy Policy</h3>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page
              and by showing a message in the app. These changes are effective
              immediately after they are posted on this page.
            </p>

            <h3>1.2 Contact Us</h3>
            <p>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at{" "}
              <a href="mailto:carlosmsilva83@gmail.com">
                carlosmsilva83@gmail.com
              </a>
              .
            </p>
          </div>
        </ScrollableBox>
        <GlobalStyle />
      </Wrapper>
    );
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    flex-direction: column;
  }

  * {
    text-shadow: none !important;
  }

  a{
    color: black;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
